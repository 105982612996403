




import Vue from "vue";
export default Vue.extend({
  props: {
    path: {
      type: String,
    },
    file: {
      type: String,
    },
    alt: {
      type: String,
    },
  },
  computed: {
    fullPath(): string {
      let base = process.env.VUE_APP_UPLOAD_BASE;
      if (!base) {
        base = "/";
      }
      return base + "/" + this.path + "/" + this.file;
    },
  },
});
