



















import Vue from "vue";
import SubHeadline from "@/components/SubHeadline.vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
  },
  components: {
    SubHeadline,
  },
});
