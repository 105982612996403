
















import Vue from "vue";
export default Vue.extend({
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
});
