











import Vue from "vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";

export default Vue.extend({
  props: {
    saveAction: {
      type: Function,
      required: true,
    },
    cancelAction: {
      type: Function,
      required: true,
    },
  },
  components: {
    PrimaryButton,
    OutlineButton,
  },
});
