











import Vue from "vue";
export default Vue.extend({
  props: {
    clickAction: {
      type: Function,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
});
