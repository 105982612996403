









































import Vue from "vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

interface DropzoneFile {
  size: number | undefined;
  name: string;
  type: string | undefined;
}

export default Vue.extend({
  model: {
    prop: "value",
    event: "onChange",
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    targetDirectory: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    value: {
      type: String,
    },
  },
  computed: {
    refName(): string {
      return this.id + "Dropzone";
    },
  },
  data() {
    const uploadServiceURL = process.env.VUE_APP_UPLOAD_SERVICE_URL;
    return {
      dropzoneOptions: {
        url: uploadServiceURL,
        thumbnailWidth: 150,
        maxFilesize: 5,
        addRemoveLinks: true,
        maxFiles: 1,
      },
    };
  },
  beforeUpdate() {
    this.setCurrentImage();
  },
  async mounted() {
    this.setCurrentImage();
  },
  methods: {
    async loadFile(filePath: string) {
      try {
        let base = process.env.VUE_APP_UPLOAD_BASE;
        const file = await fetch(base + filePath);
        return file;
      } catch (error) {
        console.error(error);
      }
    },
    async setCurrentImage() {
      // get reference to current upload component
      const ref: any = this.$refs[this.refName];
      if (this.value && ref.getAcceptedFiles().length === 0) {
        try {
          // get binary file from server
          const fileResp = await this.loadFile(
            "/" + this.targetDirectory + "/" + this.value
          );
          // get blob of file
          const fileContent = await fileResp?.blob();
          // get size and mime from blob
          const size = fileContent?.size;
          const mime = fileContent?.type;
          // create a dropzone file object
          const file: DropzoneFile = {
            size: size,
            name: this.value,
            type: mime,
          };
          // get base url
          const base = process.env.VUE_APP_UPLOAD_BASE;
          if (ref && file) {
            console.log("base", base);
            console.log(
              "target",
              base + this.targetDirectory + "/" + this.value
            );

            // add file to dropzone
            ref.manuallyAddFile(
              file,
              base + "/" + this.targetDirectory + "/" + this.value
            );
          } else {
            throw new Error("Could not add existing file to upload component");
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    maxFiles(field: string) {
      const ref: any = this.$refs[field];
      if (ref.getAcceptedFiles().length > 0) {
        ref.removeFile(ref.getAcceptedFiles()[0]);
      }
    },
    fileSendingEvent(file: any, xhr: XMLHttpRequest, formData: any) {
      const that = this;
      formData.append("subDirectory", "/" + this.targetDirectory + "/");
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          const resp = JSON.parse(xhr.responseText);
          console.log("resp", resp.fileName);
          console.log("emit change event");

          that.$emit("onChange", resp.fileName);
        }
      };
    },
    removedFileEvent(file: DropzoneFile, error: Error, xhr: XMLHttpRequest) {
      console.log("emit change event");
      this.$emit("onChange", null);
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
});
