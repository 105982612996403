





import Vue from "vue";
export default Vue.extend({
  props: {
    action: {
      type: Function,
      required: true,
    },
  },
});
