





















import Vue from "vue";
export default Vue.extend({
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },
});
